import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { SideNavigation, SideNavigationItem, SideNavigationGroup, CollapsibleSideNavigation } from '@entur/menu';
import { LeadParagraph, Paragraph, Link as LinkText } from '@entur/typography';
import { UserIcon, StatsIcon, SettingsIcon, RSSIcon, AddIcon, MenuIcon, CalendarIcon } from '@entur/icons';
import '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<SideNavigation>\n  <SideNavigationItem href=\"#menyer\" active>\n    Toppen\n  </SideNavigationItem>\n  <SideNavigationItem\n    as=\"button\"\n    type=\"button\"\n    onClick={() => alert(\'Hei på deg\')}\n  >\n    Si hei\n  </SideNavigationItem>\n  <SideNavigationItem disabled={true}>Utilgjengelig</SideNavigationItem>\n  <SideNavigationItem>\n    Andre alternativer\n    <SideNavigation>\n      <SideNavigationItem active={true} href=\"#\">\n        Sparkesykkel\n      </SideNavigationItem>\n      <SideNavigationItem disabled={true}>Ski</SideNavigationItem>\n    </SideNavigation>\n  </SideNavigationItem>\n</SideNavigation>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SideNavigation mdxType="SideNavigation">
    <SideNavigationItem href="#menyer" active mdxType="SideNavigationItem">
      Toppen
    </SideNavigationItem>
    <SideNavigationItem as="button" type="button" onClick={() => alert('Hei på deg')} mdxType="SideNavigationItem">
      Si hei
    </SideNavigationItem>
    <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Utilgjengelig</SideNavigationItem>
    <SideNavigationItem mdxType="SideNavigationItem">
      Andre alternativer
      <SideNavigation mdxType="SideNavigation">
        <SideNavigationItem active={true} href="#" mdxType="SideNavigationItem">
          Sparkesykkel
        </SideNavigationItem>
        <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Ski</SideNavigationItem>
      </SideNavigation>
    </SideNavigationItem>
  </SideNavigation>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "sidenavigation"
    }}>{`SideNavigation`}</h3>
    <ImportStatement imports="SideNavigation" mdxType="ImportStatement" />
    <Props of={SideNavigation} mdxType="Props" />
    <p><inlineCode parentName="p">{`<SideNavigation />`}</inlineCode>{` beskriver en meny, både på øverste nivå, eller som barn av et `}<inlineCode parentName="p">{`<SideNavigationItem />`}</inlineCode>{`. De kan være store eller små, og brukes inni en `}<inlineCode parentName="p">{`<SideNavigationGroup />`}</inlineCode>{` eller som en standalone meny.`}</p>
    <h3 {...{
      "id": "sidenavigationitem"
    }}>{`SideNavigationItem`}</h3>
    <ImportStatement imports="SideNavigationItem" mdxType="ImportStatement" />
    <Props of={SideNavigationItem} mdxType="Props" />
    <p><inlineCode parentName="p">{`<SideNavigationItem />`}</inlineCode>{` er et menypunkt, og er typisk en lenke. De kan inneholde en label, eller en sub-meny.`}</p>
    <h3 {...{
      "id": "sidenavigationgroup"
    }}>{`SideNavigationGroup`}</h3>
    <ImportStatement imports="SideNavigationGroup" mdxType="ImportStatement" />
    <Props of={SideNavigationGroup} mdxType="Props" />
    <p><inlineCode parentName="p">{`<SideNavigationGroup />`}</inlineCode>{` brukes til å gruppere deler av menyen sammen.`}</p>
    <h3 {...{
      "id": "collapsiblesidenavigation"
    }}>{`CollapsibleSideNavigation`}</h3>
    <ImportStatement imports="CollapsibleSideNavigation" mdxType="ImportStatement" />
    <Props of={CollapsibleSideNavigation} mdxType="Props" />
    <h2 {...{
      "id": "variasjoner"
    }}>{`Variasjoner`}</h2>
    <h3 {...{
      "id": "meny-med-ikoner"
    }}>{`Meny med ikoner`}</h3>
    <Playground __position={6} __code={'<SideNavigation>\n  <SideNavigationItem active={true} href=\"#\">\n    <UserIcon aria-hidden=\"true\" /> Min konto\n  </SideNavigationItem>\n  <SideNavigationItem disabled={true}>\n    <StatsIcon aria-hidden=\"true\" /> Statistikk\n  </SideNavigationItem>\n  <SideNavigationItem href=\"#\">\n    <SettingsIcon aria-hidden=\"true\" /> Innstillinger\n  </SideNavigationItem>\n</SideNavigation>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SideNavigation mdxType="SideNavigation">
    <SideNavigationItem active={true} href="#" mdxType="SideNavigationItem">
      <UserIcon aria-hidden="true" mdxType="UserIcon" /> Min konto
    </SideNavigationItem>
    <SideNavigationItem disabled={true} mdxType="SideNavigationItem">
      <StatsIcon aria-hidden="true" mdxType="StatsIcon" /> Statistikk
    </SideNavigationItem>
    <SideNavigationItem href="#" mdxType="SideNavigationItem">
      <SettingsIcon aria-hidden="true" mdxType="SettingsIcon" /> Innstillinger
    </SideNavigationItem>
  </SideNavigation>
    </Playground>
    <h3 {...{
      "id": "compact-side-navigation"
    }}>{`Compact side navigation`}</h3>
    <Playground __position={7} __code={'<SideNavigation size=\"small\">\n  <SideNavigationItem href=\"#menyer\" active={true}>\n    Toppen\n  </SideNavigationItem>\n  <SideNavigationItem\n    as=\"button\"\n    type=\"button\"\n    onClick={() => alert(\'Hei på deg\')}\n  >\n    Si hei\n  </SideNavigationItem>\n  <SideNavigationItem disabled={true}>Utilgjengelig</SideNavigationItem>\n  <SideNavigationItem>\n    Andre alternativer\n    <SideNavigation size=\"small\">\n      <SideNavigationItem href=\"#\">Sparkesykkel</SideNavigationItem>\n      <SideNavigationItem disabled={true}>Ski</SideNavigationItem>\n    </SideNavigation>\n  </SideNavigationItem>\n</SideNavigation>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SideNavigation size="small" mdxType="SideNavigation">
    <SideNavigationItem href="#menyer" active={true} mdxType="SideNavigationItem">
      Toppen
    </SideNavigationItem>
    <SideNavigationItem as="button" type="button" onClick={() => alert('Hei på deg')} mdxType="SideNavigationItem">
      Si hei
    </SideNavigationItem>
    <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Utilgjengelig</SideNavigationItem>
    <SideNavigationItem mdxType="SideNavigationItem">
      Andre alternativer
      <SideNavigation size="small" mdxType="SideNavigation">
        <SideNavigationItem href="#" mdxType="SideNavigationItem">Sparkesykkel</SideNavigationItem>
        <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Ski</SideNavigationItem>
      </SideNavigation>
    </SideNavigationItem>
  </SideNavigation>
    </Playground>
    <h3 {...{
      "id": "compact-side-navigation-med-ikoner"
    }}>{`Compact side navigation med ikoner`}</h3>
    <Playground __position={8} __code={'<SideNavigation size=\"small\">\n  <SideNavigationItem active={true} href=\"#\">\n    <UserIcon aria-hidden=\"true\" /> Min konto\n  </SideNavigationItem>\n  <SideNavigationItem disabled={true}>\n    <StatsIcon aria-hidden=\"true\" /> Statistikk\n  </SideNavigationItem>\n  <SideNavigationItem href=\"#\">\n    <SettingsIcon aria-hidden=\"true\" /> Innstillinger\n  </SideNavigationItem>\n</SideNavigation>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SideNavigation size="small" mdxType="SideNavigation">
    <SideNavigationItem active={true} href="#" mdxType="SideNavigationItem">
      <UserIcon aria-hidden="true" mdxType="UserIcon" /> Min konto
    </SideNavigationItem>
    <SideNavigationItem disabled={true} mdxType="SideNavigationItem">
      <StatsIcon aria-hidden="true" mdxType="StatsIcon" /> Statistikk
    </SideNavigationItem>
    <SideNavigationItem href="#" mdxType="SideNavigationItem">
      <SettingsIcon aria-hidden="true" mdxType="SettingsIcon" /> Innstillinger
    </SideNavigationItem>
  </SideNavigation>
    </Playground>
    <h3 {...{
      "id": "gruppert-meny"
    }}>{`Gruppert meny`}</h3>
    <Playground __position={9} __code={'<SideNavigationGroup title=\"En gruppe\">\n  <SideNavigation>\n    <SideNavigationItem href=\"#menyer\" active={true}>\n      Toppen\n    </SideNavigationItem>\n    <SideNavigationItem\n      as=\"button\"\n      type=\"button\"\n      onClick={() => alert(\'Hei på deg\')}\n    >\n      Si hei\n    </SideNavigationItem>\n    <SideNavigationItem disabled={true}>Utilgjengelig</SideNavigationItem>\n    <SideNavigationItem>\n      Andre alternativer\n      <SideNavigation>\n        <SideNavigationItem href=\"#\">Sparkesykkel</SideNavigationItem>\n        <SideNavigationItem disabled={true}>Ski</SideNavigationItem>\n      </SideNavigation>\n    </SideNavigationItem>\n  </SideNavigation>\n</SideNavigationGroup>\n<SideNavigationGroup title=\"Enda en gruppe\">\n  <SideNavigation>\n    <SideNavigationItem href=\"#menyer\" active={true}>\n      Toppen\n    </SideNavigationItem>\n    <SideNavigationItem\n      as=\"button\"\n      type=\"button\"\n      onClick={() => alert(\'Hei på deg\')}\n    >\n      Si hei\n    </SideNavigationItem>\n    <SideNavigationItem disabled={true}>Utilgjengelig</SideNavigationItem>\n    <SideNavigationItem>\n      Andre alternativer\n      <SideNavigation>\n        <SideNavigationItem href=\"#\">Sparkesykkel</SideNavigationItem>\n        <SideNavigationItem disabled={true}>Ski</SideNavigationItem>\n      </SideNavigation>\n    </SideNavigationItem>\n  </SideNavigation>\n</SideNavigationGroup>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SideNavigationGroup title="En gruppe" mdxType="SideNavigationGroup">
    <SideNavigation mdxType="SideNavigation">
      <SideNavigationItem href="#menyer" active={true} mdxType="SideNavigationItem">
        Toppen
      </SideNavigationItem>
      <SideNavigationItem as="button" type="button" onClick={() => alert('Hei på deg')} mdxType="SideNavigationItem">
        Si hei
      </SideNavigationItem>
      <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Utilgjengelig</SideNavigationItem>
      <SideNavigationItem mdxType="SideNavigationItem">
        Andre alternativer
        <SideNavigation mdxType="SideNavigation">
          <SideNavigationItem href="#" mdxType="SideNavigationItem">Sparkesykkel</SideNavigationItem>
          <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Ski</SideNavigationItem>
        </SideNavigation>
      </SideNavigationItem>
    </SideNavigation>
  </SideNavigationGroup>
  <SideNavigationGroup title="Enda en gruppe" mdxType="SideNavigationGroup">
    <SideNavigation mdxType="SideNavigation">
      <SideNavigationItem href="#menyer" active={true} mdxType="SideNavigationItem">
        Toppen
      </SideNavigationItem>
      <SideNavigationItem as="button" type="button" onClick={() => alert('Hei på deg')} mdxType="SideNavigationItem">
        Si hei
      </SideNavigationItem>
      <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Utilgjengelig</SideNavigationItem>
      <SideNavigationItem mdxType="SideNavigationItem">
        Andre alternativer
        <SideNavigation mdxType="SideNavigation">
          <SideNavigationItem href="#" mdxType="SideNavigationItem">Sparkesykkel</SideNavigationItem>
          <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Ski</SideNavigationItem>
        </SideNavigation>
      </SideNavigationItem>
    </SideNavigation>
  </SideNavigationGroup>
    </Playground>
    <h3 {...{
      "id": "gruppert-kompakt-meny"
    }}>{`Gruppert kompakt meny`}</h3>
    <Playground __position={10} __code={'<SideNavigationGroup title=\"En gruppe\" defaultOpen={true}>\n  <SideNavigation size=\"small\">\n    <SideNavigationItem href=\"#menyer\" active={true}>\n      Toppen\n    </SideNavigationItem>\n    <SideNavigationItem\n      as=\"button\"\n      type=\"button\"\n      onClick={() => alert(\'Hei på deg\')}\n    >\n      Si hei\n    </SideNavigationItem>\n    <SideNavigationItem disabled={true}>Utilgjengelig</SideNavigationItem>\n    <SideNavigationItem>\n      Andre alternativer\n      <SideNavigation size=\"small\">\n        <SideNavigationItem href=\"#\">Sparkesykkel</SideNavigationItem>\n        <SideNavigationItem disabled={true}>Ski</SideNavigationItem>\n      </SideNavigation>\n    </SideNavigationItem>\n  </SideNavigation>\n</SideNavigationGroup>\n<SideNavigationGroup title=\"Enda en gruppe\" defaultOpen={true}>\n  <SideNavigation size=\"small\">\n    <SideNavigationItem href=\"#menyer\" active={true}>\n      Toppen\n    </SideNavigationItem>\n    <SideNavigationItem\n      as=\"button\"\n      type=\"button\"\n      onClick={() => alert(\'Hei på deg\')}\n    >\n      Si hei\n    </SideNavigationItem>\n    <SideNavigationItem disabled={true}>Utilgjengelig</SideNavigationItem>\n    <SideNavigationItem>\n      Andre alternativer\n      <SideNavigation size=\"small\">\n        <SideNavigationItem href=\"#\">Sparkesykkel</SideNavigationItem>\n        <SideNavigationItem disabled={true}>Ski</SideNavigationItem>\n      </SideNavigation>\n    </SideNavigationItem>\n  </SideNavigation>\n</SideNavigationGroup>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SideNavigationGroup title="En gruppe" defaultOpen={true} mdxType="SideNavigationGroup">
    <SideNavigation size="small" mdxType="SideNavigation">
      <SideNavigationItem href="#menyer" active={true} mdxType="SideNavigationItem">
        Toppen
      </SideNavigationItem>
      <SideNavigationItem as="button" type="button" onClick={() => alert('Hei på deg')} mdxType="SideNavigationItem">
        Si hei
      </SideNavigationItem>
      <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Utilgjengelig</SideNavigationItem>
      <SideNavigationItem mdxType="SideNavigationItem">
        Andre alternativer
        <SideNavigation size="small" mdxType="SideNavigation">
          <SideNavigationItem href="#" mdxType="SideNavigationItem">Sparkesykkel</SideNavigationItem>
          <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Ski</SideNavigationItem>
        </SideNavigation>
      </SideNavigationItem>
    </SideNavigation>
  </SideNavigationGroup>
  <SideNavigationGroup title="Enda en gruppe" defaultOpen={true} mdxType="SideNavigationGroup">
    <SideNavigation size="small" mdxType="SideNavigation">
      <SideNavigationItem href="#menyer" active={true} mdxType="SideNavigationItem">
        Toppen
      </SideNavigationItem>
      <SideNavigationItem as="button" type="button" onClick={() => alert('Hei på deg')} mdxType="SideNavigationItem">
        Si hei
      </SideNavigationItem>
      <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Utilgjengelig</SideNavigationItem>
      <SideNavigationItem mdxType="SideNavigationItem">
        Andre alternativer
        <SideNavigation size="small" mdxType="SideNavigation">
          <SideNavigationItem href="#" mdxType="SideNavigationItem">Sparkesykkel</SideNavigationItem>
          <SideNavigationItem disabled={true} mdxType="SideNavigationItem">Ski</SideNavigationItem>
        </SideNavigation>
      </SideNavigationItem>
    </SideNavigation>
  </SideNavigationGroup>
    </Playground>
    <h3 {...{
      "id": "collapsible-side-nagivation"
    }}>{`Collapsible side nagivation`}</h3>
    <Playground __position={11} __code={'() => {\n  const [isCollapsedMenu, setIsCollapsedMenu] = React.useState(false)\n  const [isOpenMenuGroup, setIsOpenMenuGroup] = React.useState(true)\n  React.useEffect(() => {\n    if (isCollapsedMenu) {\n      setIsOpenMenuGroup(false)\n    }\n  }, [isCollapsedMenu])\n  React.useEffect(() => {\n    if (isOpenMenuGroup) {\n      setIsCollapsedMenu(false)\n    }\n  }, [isOpenMenuGroup])\n  return (\n    <CollapsibleSideNavigation\n      collapsed={isCollapsedMenu}\n      onCollapseToggle={e => setIsCollapsedMenu(e)}\n    >\n      <SideNavigationItem icon={<AddIcon />} href=\"#bruker\">\n        Opprett bruker\n      </SideNavigationItem>\n      <SideNavigationItem icon={<RSSIcon />} href=\"#nettverk\">\n        Nettverk\n      </SideNavigationItem>\n      <SideNavigationItem icon={<SettingsIcon />} href=\"#innstillinger\">\n        Innstillinger\n      </SideNavigationItem>\n      <SideNavigationGroup\n        title=\"Andre alternativer\"\n        icon={<MenuIcon />}\n        open={isOpenMenuGroup}\n        onToggle={e => setIsOpenMenuGroup(e)}\n      >\n        <SideNavigationItem icon={<CalendarIcon />} href=\"#kalender\">\n          Kalender\n        </SideNavigationItem>\n      </SideNavigationGroup>\n    </CollapsibleSideNavigation>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      SideNavigation,
      SideNavigationItem,
      SideNavigationGroup,
      CollapsibleSideNavigation,
      LeadParagraph,
      Paragraph,
      LinkText,
      UserIcon,
      StatsIcon,
      SettingsIcon,
      RSSIcon,
      AddIcon,
      MenuIcon,
      CalendarIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isCollapsedMenu, setIsCollapsedMenu] = React.useState(false);
        const [isOpenMenuGroup, setIsOpenMenuGroup] = React.useState(true);
        React.useEffect(() => {
          if (isCollapsedMenu) {
            setIsOpenMenuGroup(false);
          }
        }, [isCollapsedMenu]);
        React.useEffect(() => {
          if (isOpenMenuGroup) {
            setIsCollapsedMenu(false);
          }
        }, [isOpenMenuGroup]);
        return <CollapsibleSideNavigation collapsed={isCollapsedMenu} onCollapseToggle={e => setIsCollapsedMenu(e)} mdxType="CollapsibleSideNavigation">
        <SideNavigationItem icon={<AddIcon mdxType="AddIcon" />} href="#bruker" mdxType="SideNavigationItem">
          Opprett bruker
        </SideNavigationItem>
        <SideNavigationItem icon={<RSSIcon mdxType="RSSIcon" />} href="#nettverk" mdxType="SideNavigationItem">
          Nettverk
        </SideNavigationItem>
        <SideNavigationItem icon={<SettingsIcon mdxType="SettingsIcon" />} href="#innstillinger" mdxType="SideNavigationItem">
          Innstillinger
        </SideNavigationItem>
        <SideNavigationGroup title="Andre alternativer" icon={<MenuIcon mdxType="MenuIcon" />} open={isOpenMenuGroup} onToggle={e => setIsOpenMenuGroup(e)} mdxType="SideNavigationGroup">
          <SideNavigationItem icon={<CalendarIcon mdxType="CalendarIcon" />} href="#kalender" mdxType="SideNavigationItem">
            Kalender
          </SideNavigationItem>
        </SideNavigationGroup>
      </CollapsibleSideNavigation>;
      }}
    </Playground>
    <p>{`Bruk `}<inlineCode parentName="p">{`collapisibleButtonPosition`}</inlineCode>{` for plasseringen av collapse-knappen ift toppen av sidemenyen. Eksempel `}<inlineCode parentName="p">{`2rem`}</inlineCode>{`, `}<inlineCode parentName="p">{`25%`}</inlineCode>{` og lignende.`}</p>
    <p><strong parentName="p">{`Obs:`}</strong>{` Husk og inkluder `}<inlineCode parentName="p">{`icon`}</inlineCode>{`-propen for alle menypunktene.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <Paragraph mdxType="Paragraph">
  Side navigation er anbefalt å brukes hvis applikasjonen krever en global
  navigasjon, og inneholder mer enn fem sider. Navigasjonsmønsteret støtter
  opptil to nivåer av navigasjon (Nivå 1 og Nivå 2), men det er ikke nødvendig
  for hvert navigasjonselement å ha samme antall navigasjonsnivåer. Ytterligere
  et nivå over dette kan legges til ved å benytte{' '}
  <LinkText as={Link} to="/komponenter/navigasjon/top-navigation" mdxType="LinkText">
    Top navigation
  </LinkText>{' '}
  i tillegg. Konvensjonen for denne typen menystruktur er å vise den på venstre
  side. Hvis du har færre en 3 menypunkter i navigasjonen, vurder heller å bruk
  en{' '}
  <LinkText as={Link} to="/komponenter/navigasjon/top-navigation" mdxType="LinkText">
    Top navigation
  </LinkText>
  .
    </Paragraph>
    <h3 {...{
      "id": "variasjoner-1"
    }}>{`Variasjoner`}</h3>
    <p>{`Side navigation komponenten finnes både med og uten ikon.
Ikoner på et MenuItem skal kun benyttes på nivå 1. Komponenten er tilgjengelig i normal og kompakt versjon.
Den kompakte versjonen er utviklet for å tilby enkel oversikt og unngå overdreven scrolling hos våre proffbrukere,
nå som mange av Enturs applikasjoner har fått mer innhold.`}</p>
    <p>{`Noen applikasjoner kan trenge en mer avansert menystruktur. I de tilfellene kan man bruke MenuGroups til å gruppere menyvalg sammen.
Idéen med disse er å kunne tilby en logisk strukturering og forenkle navigasjon i et ellers uoversiktlig innhold.
Menygruppene kan kollapses, men man kan selv velge om alle (eller noen av dem) skal være åpne til å begynne med.
Hver menygruppe skal helst kun ha ett nivå under seg; unngå nøstede undermenyer i menygruppen.`}</p>
    <h3 {...{
      "id": "inaktive-elementer"
    }}>{`Inaktive elementer`}</h3>
    <p>{`Om man ønsker å synliggjøre at en lenke finnes, men er utilgjengelig av en eller annen grunn,
kan man gjøre et menyelement "disabled". Dette er noe man helst ikke skal gjøre,
siden det ikke er noen god måte å fortelle brukeren hvorfor elementet ikke er tilgjengelig for dem.
Vurder heller å fjerne elementet fra listen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      